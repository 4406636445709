// If you want to override variables do it here
@import "variables";

@import '~@coreui/icons/css/free.css';

@import '~font-awesome/css/font-awesome.min.css';

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';


@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
@import '~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
@import '~react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
@import '~ol/ol.css';

@import '~react-bootstrap-typeahead/css/Typeahead.scss';

@import '~react-datepicker/src/stylesheets/datepicker.scss';

@import 'ol-layerswitcher.css';

@import '~react-splitter-layout/lib/index.css';

// splitter styles
@import "splitter";

// karte style
// @import "karte";

// layouting overrides and custom
@import "layout";

// form fields styles
@import "forms";

// grid styles
@import "grid";

// grid styles
@import "atradne";

// alerts styles
@import "alerts";

// header styles
@import "header";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

//map styles
@import "map";

@import "uploader";

@import '~draft-js/dist/Draft.css';

@import "lnb_faili_flex_container";

@import "lnb_file_upload_flex_container";
