
.alert-area-out {
    @extend .pos-center-out;
    top: 10px;
}

.alert-area-in {
    @extend .pos-center-in;
    width: 400px;
}
