.app {
    height: 100vh;
}

.app-body {
    //height: calc(100vh - #{$navbar-height} - #{$footer-height} );
    flex: 2;
    margin: 0px !important;
}

.app-header {
    flex: 0 0 auto;
    height: unset !important;
    position: unset !important;
}

.app-footer {
    position: unset !important;
    font-size: $font-size-sm;
    flex: 0 0 $footer-height;
}

.main {
    height: 100%;
    padding: 3px;
    overflow: hidden;
}

.main-scrollable {
    overflow: auto;
}

.main div {
    @extend .animated;
    @extend .fadeIn;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-10-hor {
    padding: 0px 10px 0px 10px;
}

.margin-5-hor {
    margin: 0px 10px 0px 10px;
}

.margin-10 {
    margin: 10px;
}

.tab-content {
    border-radius: $border-radius;
}

.actionButtons .btn, .actionButtons .field {
    margin: 2px;
}

.pos-center-out {
    position: absolute;
    left: 50%;
    z-index: 1200;
}

.pos-center-in {
    position: relative;
    left: -50%;
}

.loading-out {
    @extend .pos-center-out;
    top: 180px;
    animation-name: unset;
    animation-name: unset;
}
.loading-in {
    @extend .pos-center-in;
    color: $primary;
}
.data-panel {
    padding: 10px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
}
.nav-item-child-level-1 {
    padding-left: 15px !important;
    overflow-x: hidden;
    &:hover {
        overflow-x: hidden !important;
    }
}
.nav-item-child-level-2 {
    padding-left: 20px !important;
    overflow-x: hidden;
    &:hover {
        overflow-x: hidden !important;
    }
}
.nav-item-parent {
    font-variant: small-caps;
    font-weight: bolder;
    text-decoration: underline !important;
    overflow-x: hidden;
}

@media (min-width: 992px) {
    .nav-icon {
        & > svg {
            height: 18px;
            width: 50px;
        }
    }
}