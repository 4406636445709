/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover, .active {
    background-color: rgba($map_base_color, 0.9);
  }  
  outline: none !important;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: rgba($map_base_color, 0.6);
  color: #eee;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  margin-bottom: 0px !important;
  p {
    line-height: $map_btn_size;
    margin: 0.2rem 0px;
  }
  height: calc(#{$map_btn_size} + 0.4rem);
  padding-right: 10px;
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: .9em;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  font-size: 0.8rem;
  padding: 0.5rem;
}
