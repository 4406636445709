// Variable overrides
$navbar-brand-width:                  200px;
$body-bg:                         #f5f5f5;

$breadcrumb-padding-y:              .5rem;
$breadcrumb-margin-bottom:          0rem;

$navbar-height:                       45px;
$nav-link-padding-y:                .2rem;

$footer-height:                       20px;
$sidebar-minimizer-height: $footer-height;

$navbar-brand-font-size:            1rem;

$breadcrumb-borders: (
);

$blue:       #31669C;
$lvgmc_color: #0b4787;

$theme-colors: (
  "color_0": #999999,
  "color_1": #6b5b95,
  "color_2": #feb236,
  "color_3": #d64161,
  "color_4": #ff7b25,
  "color_5": #2c76e4,
  "color_6": #79ad00,
  "color_7": #f14dbb,
  "color_8": #7debeb,
  "color_9": #3c632c,
  "color_10": #dbff5b,
  "color_krajumi": #BC2732,
  "color_limiti": #0071f6,
  "color_pases": #f7f546,
  "color_licences": #ffbebf,
  "color_kudra_s": #5fbe8f,
  "color_kudra_p": #a4deef,
  "color_kval_augsta": #00a8e6,
  "color_kval_laba": #38a800,
  "color_kval_videja": #ffff00,
  "color_kval_slikta": #ffaa00,
  "color_kval_loti_slikta": #e60000
);

$font-size-base: .85rem;
$input-padding-y: .375rem;
$input-padding-x: .375rem;