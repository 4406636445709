
.table td {
    padding: 0.3rem;
}

//.react-bootstrap-table-pagination{
//    min-height: 150px;
//}

.danger{
    color: $danger;
    font-weight: bold;
}

.warning{
    color: $warning;
    font-weight: bold;
}

.coord-grid-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 3px;
    .editable-grid-info{
        font-size: 90%;
        font-style: italic;
    }
    .buttons{
        flex-shrink: 0;
        button {
            margin: 0px 3px 0px 3px;
        }
    }
}

.saraksts-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}

.react-bootstrap-table , .multifield-table-container{
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: auto;
    min-height: 2em;
    margin-bottom: 5px;
    thead th {
        position: sticky;
        padding: 0.3em;
        top: 0;
        background: mix($primary, #eeeeee, 30);
        z-index: 10;
        outline: 1px solid #c8ced3;
        border:none;
        outline-offset: -1px;
        text-align: center;
        vertical-align: bottom;
    }
    .row-archived {
        font-style: italic;
        color: #666666;
    }
    .row-warning {
        background: $warning;
    }
    th.sortable:focus {
        outline: 0px;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    td {
        border: 1px solid #c8ced3;
    }
}

.react-bootstrap-table {
    td {
        padding: 0.2rem !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }
    td:hover {
        text-overflow: unset !important;
        overflow: unset !important;
    }
}

.saraksts{

    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;

    .actionButtons {
        flex: 0 0 auto;
    }

    .scroll-btn{
        position: absolute;
        top: 50%;
        z-index: 200;
        height: 30%;
        opacity: 0.5;
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
        max-height: 100px;
    }

    .scroll-left-btn{
        @extend .scroll-btn;
        left: 0;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    .scroll-right-btn{
        @extend .scroll-btn;
        right: 0;
    }

    .grid-saraksts {
        flex: 2 2 auto;
        align-items: flex-start;
    }

    .table-400 {
        min-width: 400px;
    }
    .table-600 {
        min-width: 600px;
    }
    .table-800 {
        min-width: 800px;
    }
    .table-1000 {
        min-width: 1000px;
    }
    .table-1200 {
        min-width: 1200px;
    }
    .table-1400 {
        min-width: 1400px;
    }

    .table-bottom-bar {
        flex: 0 0 auto;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .part {
            display: flex;
            flex-wrap: wrap;
            .pagination {
                margin: 2px;
            }
            .pagination-total {
                margin: 2px;
                p {
                    text-align: center;
                    position: relative;
                    top: 50%;
                    -ms-transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                  }
            }
        }
        .part-pages{
            margin: 0;
            display: table;
            button {
                margin-right: 5px;
            }
        }
    }
}

.badge-column {
    display: inline;
    margin: 0px 5px 0px 0px;
}

.number-filter-comparator {
    width:46px  !important;
}

.date-filter-comparator {
    width:46px  !important;
}

.number-filter-input {
    margin-left: 3px !important;
    width: calc(100% - 3px) !important;
}

.date-filter-input {
    margin-left: 3px !important;
    width: 60px !important;
}

.table-nowrap > tbody > tr > td {
  white-space: nowrap;
}

.table th {
    padding: 0.2rem !important;
    text-overflow: ellipsis !important;
}
.table th:hover{
    text-overflow: unset !important;
}

.selection-row {
    background-color: rgba(theme-color("primary"), .6) !important;
}

.selection-row-saist {
    background-color: rgba(theme-color("primary"), .5) !important;
}

.selection-row-iepr {
    background-color: rgba(theme-color("primary"), .3) !important;
}

.multifield-table {
    th {
        position: sticky;
        top: 0;
        z-index: 2;
      }
    tr.row-selected {
        background-color: rgba(theme-color("primary"), .6) !important;
    }
}

.multifield-table-container {
    overflow-y: auto;
    margin-bottom: 5px;
    thead th {
        position: sticky;
        top: 0;
        background: #b5c5d5;
        z-index: 10;
        outline: 1px solid #c8ced3;
        border: 1px solid #c8ced3;
        outline-offset: -1px;
        text-align: center;
        vertical-align: middle;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    td {
        border: 1px solid #c8ced3;
    }

}

td.flex-child {
    display: flex;
}

td.red-back {
    background-color: $danger;
}

td.wide {
    min-width: 150px;
}
td.narrow {
    max-width: 75px;
}
td.narrow-number {
    width: 75px;
}
td.semi-narrow {
    max-width: 100px;
}
td.fixed-200 {
    width: 200px;
}
td.td-button {
    width: 2rem;
}

.cell-button {
    padding: 2px;
    margin: 2px 5px;
    float: right;
}
.cell-border-bottom{
    border-bottom: 2px solid $primary !important;
}

.plain-table {
    tr {
        background-color: rgba(255, 255, 255, 0.0) !important;
    }
    td {
        border-width: 0px !important;
    }
}

.karte-audits {
    height: 300px;
    width: 100%;
    overflow: hidden;
}

.kvalitate-1 {
    background-color: #00b0f0 !important;
}
.kvalitate-1-fg {
    color: #00b0f0 !important;
}

.kvalitate-2 {
    background-color: #92d050 !important;
}
.kvalitate-2-fg {
    color: #92d050 !important;
}
.kvalitate-3 {
    background-color: #ffff00 !important;
}
.kvalitate-3-fg {
    color: #ffff00 !important;
}
.kvalitate-4 {
    background-color: #ffc000 !important;
}
.kvalitate-4-fg {
    color: #ffc000 !important;
}
.kvalitate-5 {
    background-color: #ff0000 !important;
    color: white;
}
.kvalitate-5-fg {
    color: #ff0000 !important;
}
.badge-filter {
    font-size: 1em;
    margin-right: 5px;
}
.button-on-left {
    margin-right: 15px;
}
.inline-badge {
    position: relative !important;
    right: 0px !important;
    margin-left: 5px;
    margin-right: 5px;
}

.year-filter {
    .prev {
        margin-right: 1em;
    }
    .next {
        margin-left: 1em;
    }
}

th {
    font-weight: 600;
}
